import { get, set } from "automate-redux";
import { nanoid } from "nanoid";
import { axios } from "../client";
import { getOrgDetails, getOrgSettings, getSelectedFcYearId, getSelectedOrgId } from "./user-management";
import { getSettingRecordDetails } from "./settings";
import {
  getLastTimestampOfRecords,
  mergeExistingAndUpdatedRecords,
  printOrSaveBlobFromServer,
  uploadFile,
  uploadFiles,
} from "../utils";
import store from "../store";
import { getAccountSubTypesForBook } from "../components/transactions/utils";
import { trnTypes } from "../constants";

export const loadStates = () => async (dispatch) => {
  const { status, data } = await axios.get("/v1/getStates");
  if (status !== 200) {
    throw data;
  }
  dispatch(set("states", data));
};

export const loadInbuiltPrintTemplates = () => async (dispatch) => {
  const { status, data } = await axios.get("/v1/getInbuiltPrintTemplates");
  if (status !== 200) {
    throw data;
  }
  dispatch(set("inbuiltPrintTemplates", data));
};

export const mergeExistingAndUpdatedMastersFromServer =
  (updatedMastersFromServer) => (dispatch, getState) => {
    const state = getState();
    const oldMasters = state.masters ?? {};
    const newMasters = { ...oldMasters };
    Object.entries(updatedMastersFromServer).forEach(([masterType, updatedRecords]) => {
      if (!oldMasters[masterType]?.fetchedOnce) {
        newMasters[masterType] = {
          data: updatedRecords.sort((a, b) => (a.name < b.name ? -1 : 1)),
          fetchedOnce: true,
        };
      } else {
        const existingMasterRecords = oldMasters[masterType]?.data;
        const finalMasterRecords = mergeExistingAndUpdatedRecords(
          existingMasterRecords,
          updatedRecords
        );
        newMasters[masterType].data = finalMasterRecords.sort((a, b) => (a.name < b.name ? -1 : 1));
      }
    });
    dispatch(set("masters", newMasters));
  };

export const loadMasters = (masterTypes) => async (dispatch, getState) => {
  const state = getState();
  const oldMasters = state.masters ?? {};
  const mastersToFetch = masterTypes.map((type) => {
    const master = oldMasters[type] ?? {};
    const obj = { type };
    if (master.fetchedOnce) {
      obj.last_timestamp = getLastTimestampOfRecords(master.data ?? []);
    }
    return obj;
  });

  const params = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    masters: mastersToFetch,
  };

  const { status, data } = await axios.get("/v1/getMasters", { params });
  if (status !== 200) {
    throw data;
  }

  dispatch(mergeExistingAndUpdatedMastersFromServer(data));
};

export const invalidateMasters = (masterTypes) => (dispatch, getState) => {
  const state = getState();
  const oldMasters = state.masters ?? {};
  const newMasters = { ...oldMasters };
  masterTypes.forEach(type => {
    newMasters[type] = {
      data: [],
      fetchedOnce: false
    }
  })
  dispatch(set("masters", newMasters));
}

export const fetchMasterRecord = async (type, id) => {
  const state = store.getState();

  const params = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    type,
    id,
  };

  const { status, data } = await axios.get("/v1/getMasterRecord", { params });
  if (status !== 200) {
    throw data;
  }

  return data;
};

export const createMasterRecord = (type, values, files) => async (dispatch, getState) => {
  const state = getState();
  const id = nanoid();

  const existingMasterRecords = state.masters?.[type]?.data;
  const bodyData = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    type,
    last_timestamp: getLastTimestampOfRecords(existingMasterRecords),
    data: JSON.stringify({ ...values, id }),
  };
  const data = await uploadFiles("/v1/createMasterRecord", files, bodyData)

  const newRecordsFromServer = data.data;
  dispatch(mergeExistingAndUpdatedMastersFromServer({ [type]: newRecordsFromServer }));

  return Promise.resolve(id);
};

export const updateMasterRecord = (type, id, values, files) => async (dispatch, getState) => {
  const state = getState();
  const existingMasterRecords = state.masters?.[type]?.data;
  const bodyData = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    type,
    id,
    last_timestamp: getLastTimestampOfRecords(existingMasterRecords),
    data: JSON.stringify(values),
  };

  const data = await uploadFiles("/v1/updateMasterRecord", files, bodyData)

  const newRecordsFromServer = data.data;
  dispatch(mergeExistingAndUpdatedMastersFromServer({ [type]: newRecordsFromServer }));

  return Promise.resolve(id);
};

export const deleteMasterRecords = (type, ids) => async (dispatch, getState) => {
  const state = getState();
  const request = {
    org_id: getSelectedOrgId(state),
    type,
    ids,
  };
  const { status, data } = await axios.post("/v1/deleteMasterRecords", request);
  if (status !== 200) {
    throw data;
  }
  const oldMasterData = state.masters[type].data;
  const finalMasterData = oldMasterData.filter((obj) => !ids.includes(obj.id));
  dispatch(set(`masters.${type}.data`, finalMasterData));
};

export const searchGstin = async (gstNo) => {
  const state = store.getState();
  const orgId = getSelectedOrgId(state);
  const fyId = getSelectedFcYearId(state);
  const { status, data } = await axios.get("/v1/searchGstin", {
    params: { org_id: orgId, fy_id: fyId, gstin: gstNo },
  });
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const printCover = async (accountIds, format, trnIds) => {
  const state = store.getState();
  const reqBody = {
    org_id: getSelectedOrgId(state),
    ids: accountIds,
    trn_ids: trnIds,
    format,
  };
  const response = await axios.post(
    "/v1/downloadPartyCover",
    reqBody,
    { responseType: "arraybuffer" }
  );
  if (response.status !== 200) {
    console.log("Error", response.data);
    return;
  }
  printOrSaveBlobFromServer(response, "print");
};

export const printItemLabel = async (masterType, ids, format, copies) => {
  const state = store.getState();
  const reqBody = {
    org_id: getSelectedOrgId(state),
    master_type: masterType,
    ids,
    format,
    copies,
  };
  const response = await axios.post(
    "/v1/downloadItemLabel",
    reqBody,
    { responseType: "arraybuffer" }
  );
  if (response.status !== 200) {
    console.log("Error", response.data);
    return;
  }
  printOrSaveBlobFromServer(response, "print");
};

export const sendWhatsappMessageToAccounts = async (accountIds, msg, file) => {
  const state = store.getState();
  const reqBody = {
    org_id: getSelectedOrgId(state),
    ids: accountIds,
    msg,
  };
  if (!file) {
    const { status, data } = await axios.post(
      "/v1/sendWhatsappMessageToAccounts",
      reqBody,
    );
    if (status !== 200) {
      throw data
    }
    return data;
  }
  return uploadFile("/v1/sendWhatsappMessageToAccounts", file, reqBody);
};

/* ***************************** Getters ****************************** */
export const getMasterRecords = (state, type) => get(state, `masters.${type}.data`, []);
export const getHsnDetailsWithLatestTaxRate = (state) => {
  const hsnRecords = getMasterRecords(state, 'HSN');
  const taxDetails = getMasterRecords(state, 'TAX');
  const taxRateMap = taxDetails.reduce((prev, curr) => {
    prev[curr.id] = curr.details.rate;
    return prev;
  }, {})
  const hsnRecordsWithTaxRates = hsnRecords.map(obj => {
    const latestTax = obj.details.taxes.slice().sort((a, b) => a.date > b.date ? -1 : 1)[0];
    return {
      ...obj,
      rate: taxRateMap[latestTax?.tax_id]
    }
  })
  return hsnRecordsWithTaxRates;
};

export const getItemsWithAllDetails = (state) => {
  const items = getMasterRecords(state, "ITM");
  const itemGroupMap = getMasterRecords(state, 'ITG').reduce((prev, curr) => {
    prev[curr.id] = curr;
    return prev;
  }, {});
  const hsnMap = getHsnDetailsWithLatestTaxRate(state).reduce((prev, curr) => {
    prev[curr.id] = curr;
    return prev;
  }, {});
  const itemWithAllDetails = items.map(obj => ({ ...obj, hsn: hsnMap[obj.hsn_id], itg: itemGroupMap[obj.itg_id] }));
  return itemWithAllDetails;
};

export const getStates = (state) => get(state, "states");
export const getAccountGroupHeads = (state) => getMasterRecords(state, "HAGM");
export const getAccountGroups = (state, includeInactiveGroups) => {
  const orgSettings = getOrgSettings(state);
  const inactiveAgmIds = orgSettings.inactive_agm?.options || [];
  const accountGroups = getMasterRecords(state, "AGM");
  if (!includeInactiveGroups && inactiveAgmIds.length) {
    return accountGroups.filter(obj => !inactiveAgmIds.includes(obj.id))
  } else {
    return accountGroups;
  }
};
export const getAccounts = (state) => getMasterRecords(state, "ACM");
export const getDaybooks = (state) => getAccounts(state).filter((obj) => obj.type === "DBK" || obj.type === "EXPP");
export const getDaybooksForTrnType = (state, trnType) => {
  if ([trnTypes.PURCHASE, trnTypes.QUICK_PURCHASE].includes(trnType)) {
    return getAccounts(state).filter(obj => obj.type === "EXPP" || (obj.type === "DBK" && obj.sub_type === "PUR"));
  }
  const accountSubTypes = getAccountSubTypesForBook(trnType);
  const result = getDaybooks(state).filter((obj) => accountSubTypes.includes(obj.sub_type));
  return result
};
export const getParties = (state) => {
  const accountGroups = getAccountGroups(state);
  const partyAccountGroups = accountGroups.filter(obj => obj.expense === "BALANCE SHEET" && obj.type === "Party")
  const partyAccountGroupsMap = partyAccountGroups.reduce((acc, curr) => {
    acc[curr.name] = true;
    return acc;
  }, {})
  const accounts = getAccounts(state);
  const partyAccounts = accounts.filter(obj => partyAccountGroupsMap[obj.agm]);
  return partyAccounts;
}
export const getTradingAccounts = (state) => {
  const accountGroups = getAccountGroups(state);
  const tradingAcountGroups = accountGroups.filter(obj => obj.expense)
  const tradingAccountGroupsMap = tradingAcountGroups.reduce((acc, curr) => {
    acc[curr.name] = true;
    return acc;
  }, {})
  const accounts = getAccounts(state);
  const tradingAccounts = accounts.filter(obj => tradingAccountGroupsMap[obj.agm]);
  return tradingAccounts;
}
export const getAgencyParties = (state) => {
  const accountGroups = getAccountGroups(state);
  const agencyPartyAccountGroups = accountGroups.filter(obj => obj.expense === null && obj.type === "Party");
  const agencyPartyAccountGroupsMap = agencyPartyAccountGroups.reduce((acc, curr) => {
    acc[curr.name] = true;
    return acc;
  }, {})
  const accounts = getAccounts(state);
  const agencyPartyAccounts = accounts.filter(obj => {
    return agencyPartyAccountGroupsMap[obj.agm];
  });
  return agencyPartyAccounts;
}
export const getItems = (state) => getMasterRecords(state, "ITM");
export const getItemGroups = (state) => getMasterRecords(state, "ITG");
export const getItemScreens = (state) => getMasterRecords(state, "ITS");
export const getSubDesigns = (state) => getMasterRecords(state, "SUBDESIGN");
export const getRateCategories = (state) => getMasterRecords(state, "RATE_CATEGORY")
  .sort((a, b) => a.details?.order !== b.details?.order ? (a.details?.order < b.details?.order ? -1 : 1) : (a.name < b.name ? -1 : 1));
export const getJobTypes = (state) => getMasterRecords(state, "JOB");
export const getUnits = (state) => getMasterRecords(state, "UNIT")
  .sort((a, b) => a.details?.order !== b.details?.order ? (a.details?.order < b.details?.order ? -1 : 1) : (a.name < b.name ? -1 : 1));
export const getPackingTypes = (state) => getMasterRecords(state, "PACKING")
  .sort((a, b) => a.details?.order !== b.details?.order ? (a.details?.order < b.details?.order ? -1 : 1) : (a.name < b.name ? -1 : 1));
export const getHSNDetails = (state) => getMasterRecords(state, "HSN");
export const getCities = (state) => Array.from(new Set(getAccounts(state).map(obj => obj.city))).filter(c => c).sort();
export const getTaxDetails = (state) => getMasterRecords(state, "TAX");
export const getBanks = (state) => getMasterRecords(state, "BANK");
export const getSalesperson = (state) => getMasterRecords(state, "SALESPERSON");
export const getContacts = (state) => getMasterRecords(state, "CONTACT");
export const getFirms = (state) => getMasterRecords(state, "FIRM");
export const getPartyGroups = (state) => getMasterRecords(state, "PGM");
export const getRemarks = (state) => getMasterRecords(state, "REMARK");

export const getBrokers = (state) => getAccounts(state).filter((obj) => obj.type === "BRK");
export const getTransporters = (state) => getAccounts(state).filter((obj) => obj.type === "TRN");
export const getTdsAccounts = (state) =>
  getAccounts(state).filter((obj) => obj.type === "TAX" && obj.sub_type === "TDS");

export const getPartyGroupOrAccounts = (state) => {
  const parties = getParties(state);
  const groups = getPartyGroups(state);
  const groupsMap = groups.reduce((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {});
  const result = [];
  parties.forEach(obj => {
    if (!obj.pgm_id) {
      result.push({ id: obj.id, name: obj.name, type: obj.type })
    } else if (groupsMap[obj.pgm_id]) {
      result.push({ ...groupsMap[obj.pgm_id], type: obj.type });
      groupsMap[obj.pgm_id] = undefined;
    }
  })
  return result.sort((a, b) => a.name < b.name ? -1 : 1);
}
export const getExpenseDetails = (state, trnType) => {
  const expenses = getSettingRecordDetails(state, "EXPENSES", "EXPENSES").expenses ?? [];
  if (!trnType) {
    return expenses;
  }
  return expenses.filter(obj => {
    if (!obj.trn_types || obj.trn_types.mode === "all") {
      return true;
    }
    return obj.trn_types.options.some(option => option === trnType)
  })
};
export const getHSNDetailById = (state, id) => {
  const hsnDetails = getHSNDetails(state);
  const index = hsnDetails.findIndex((obj) => obj.id === id);
  if (index === -1) {
    return {};
  }
  const hsnDetail = hsnDetails[index];
  return hsnDetail.details ? hsnDetail.details : {};
};

export const getDaybookTypes = (state) => {
  const companyDetails = getOrgDetails(state);
  const industry = companyDetails.industry;
  const daybookTypes = [
    { name: "Cash Book", type: "CASH" },
    { name: "Bank", type: "BANK" },
    { name: "Sales", type: "SAL" },
    { name: "Sales Return", type: "RSL" },
    { name: "Excess RG", type: "XRSL" },
    { name: "Sales Order", type: "SLO" },
    { name: "Sales Challlan", type: "SLC" },
    { name: "Sales Credit Note", type: "SCN" },
    { name: "Sales Debit Note", type: "SDN" },
    { name: "Purchase", type: "PUR" },
    { name: "Purchase Return", type: "RPU" },
    { name: "Purchase Order", type: "PUO" },
    { name: "Purchase Challan", type: "PRC" },
    { name: "Purchase Credit Note", type: "PCN" },
    { name: "Purchase Debit Note", type: "PDN" },
  ];

  if (industry === "Textile") {
    daybookTypes.push(
      { name: "Mill Issue", type: "GIS" },
      { name: "Mill Receive", type: "PRO" },
      { name: "Lump Cutting Entry", type: "CIS" },
      { name: "Job Issue", type: "JIS" },
      { name: "Job Receive", type: "JRC" },
      { name: "Job Bill", type: "PJW" }
    );
  }

  if (industry === "Jobwork") {
    daybookTypes.push({ name: "Job Inward", type: "JIN" }, { name: "Job Outward", type: "JOU" })
  }

  return daybookTypes;
};

export const getAccountTypes = (state) => {
  const companyDetails = getOrgDetails(state);
  const industry = companyDetails.industry;

  const accountTypes = [
    { name: "Customer", tag: "DBT" },
    { name: "Supplier", tag: "SPL" },
  ];

  if (industry === "Agency") {
    accountTypes.push({ name: "Agency Customer", tag: "ADBT" });
    accountTypes.push({ name: "Agency Supplier", tag: "ASPL" });
  }

  if (industry === "Textile") {
    accountTypes.push({ name: "Mill Processor", tag: "MIL" });
    accountTypes.push({ name: "Job Processor", tag: "JBW" });
  }

  accountTypes.push(
    { name: "Transporter", tag: "TRN" },
    { name: "Broker / Agency", tag: "BRK" },
    { name: "Employee", tag: "EMP" },
    { name: "Capital Account", tag: "CAP" },
    { name: "Partner Account", tag: "PRT" },
    { name: "Daybook", tag: "DBK" },
    { name: "Tax", tag: "TAX" },
    { name: "Fixed Assets", tag: "FAST" },
    { name: "Current Asset", tag: "CAST" },
    { name: "Loan Liability", tag: "LONL" },
    { name: "Loan Receivable", tag: "LONA" },
    { name: "Expense - Profit And Loss", tag: "EXPP" },
    { name: "Expense - Trading", tag: "EXPT" },
    { name: "Income - Profit And Loss", tag: "INCP" },
    { name: "Income - Trading", tag: "INCT" },
    { name: "Round Off Account", tag: "RND" },
    { name: "Other", tag: "OTH" }
  );

  return accountTypes;
};
